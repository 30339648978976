import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import PropTypes from "prop-types";
import {setConfiguration} from "../../../../../IOT/device-functions/ScaleHubFunctions";
import _ from "lodash";
import {connect} from "react-redux";
import Switch from "../../../../../components/basics/switch/Switch";
import LabeledInput from "../../../../../components/basics/input/labeled-input/LabeledInput";
import LabeledSlider from "../../../../../components/basics/slider/labeled-slider/LabeledSlider";
import {convertWeightUnitTo} from "../../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../../constans/unitTypes";
import {Collapse} from "react-bootstrap";
import {LoadedDataTypes} from "../../../../../constans/devices";
import {ScaleHubCommandTypes} from "../../../../../constans/mqttMessages";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import moment from "moment";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

const SilosesNumber = 8;

const defaultValues = {
    flashDisplay: false,
    isPin: false,
    pin: "0000",
    sweepWeights: false,
    minWeights: new Array(SilosesNumber).fill(0).map((o, index) => ({
        number: index + 1,
        value: 0
    }))
};

export class GeneralSetup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ...defaultValues,
            changed: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!this.state.changed) {
            this.setState({
                ...this.getValues(nextProps)
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState) || !_.isEqual(this.props.t("newSettings.silo.generalSetup"), nextProps.t("newSettings.silo.generalSetup"));
    }

    getValues = (props = this.props) => {
        const {siloses, shadows} = props;
        let device = siloses[0];
        try {
            let shadow = shadows.get(device.DevID);
            return {
                flashDisplay: shadow.configuration.flashDisplay || defaultValues.flashDisplay,
                isPin: shadow.configuration.isPin || defaultValues.isPin,
                pin: (shadow.configuration.pin + "" || defaultValues.pin).padStart(4, '0'),
                sweepWeights: shadow.configuration.sweepWeights || defaultValues.sweepWeights,
                minWeights: shadow.configuration.minWeights || defaultValues.minWeights,
                loadedDataFrom: LoadedDataTypes.SHADOW,
                SetTime: shadow.metadata.motorSetup,
                device
            }
        } catch (e) {
            return {
                flashDisplay: _.get(device, `Settings.Configuration.${ScaleHubCommandTypes.SET_CONFIGURATION}.flashDisplay`, defaultValues.flashDisplay),
                isPin: _.get(device, `Settings.Configuration.${ScaleHubCommandTypes.SET_CONFIGURATION}.isPin`, defaultValues.isPin),
                pin: _.get(device, `Settings.Configuration.${ScaleHubCommandTypes.SET_CONFIGURATION}.pin`, defaultValues.pin).padStart(4, '0'),
                sweepWeights: _.get(device, `Settings.Configuration.${ScaleHubCommandTypes.SET_CONFIGURATION}.sweepWeights`, defaultValues.sweepWeights),
                minWeights: _.get(device, `Settings.Configuration.${ScaleHubCommandTypes.SET_CONFIGURATION}.minWeights`, defaultValues.minWeights),
                SetTime: _.get(device, `Settings.Configuration.${ScaleHubCommandTypes.SET_CONFIGURATION}.SetTime`, 0),
                loadedDataFrom: device && device.Settings && device.Settings.Configuration && device.Settings.Configuration[ScaleHubCommandTypes.SET_CONFIGURATION] ? LoadedDataTypes.DYNAMO : LoadedDataTypes.NO_DATA,
                device
            };
        }
    };


    onClick = () => {
        const {siloses} = this.props;
        let valid = !Object.keys(this.validate()).length;
        if (valid) {
            const {flashDisplay, isPin, pin, sweepWeights, minWeights} = this.state;
            //todo: dorobic wysylanie na wiele urzadzen jak backend bedzie to umial robic
            siloses.forEach(device => {
                setConfiguration(device, {
                    flashDisplay: !!flashDisplay,
                    isPin: !!isPin,
                    pin: pin,
                    sweepWeights: !!sweepWeights,
                    minWeights: minWeights
                })
            })
        }
    };

    setValue = (key, value) => {
        this.setState(state => {
            let cloned = _.cloneDeep(state);
            _.set(cloned, key, value);
            return {
                ...cloned,
                changed: true
            }
        })
    };

    validate = () => {
        let errors = {};
        const {t} = this.props;
        const {pin} = this.state;
        if (!(/([0-9]{4})$/.test(pin + ""))) {
            errors.pin = t("required")
        }
        return errors
    };

    renderMinWeights = () => {
        const {
            minWeights
        } = this.state;
        return (
            <div>
                {
                    new Array(SilosesNumber).fill(1).map((member, index) => (
                        <div>
                            <LabeledSlider onChange={value => this.setValue(`minWeights[${index}].value`, value)}
                                           label={i18next.t("newSettings.silo.generalSetup.minWeights", {type: index + 1})}
                                           min={0}
                                           max={5 * 1000 * 1000} step={1000} value={minWeights[index].value}
                                           valueFormatter={(value) => convertWeightUnitTo(value, {
                                               showUnit: true,
                                               unit: UnitTypes.MEDIUM,
                                               fixed: 0
                                           })}/>
                        </div>
                    ))
                }
            </div>
        )
    };

    render() {
        const {t} = this.props;
        const {flashDisplay, isPin, pin, sweepWeights, loadedDataFrom, SetTime, device} = this.state;
        let validate = this.validate();
        return (
            <DeviceSettingsCard title={t("newSettings.silo.generalSetup.title")}
                                disabled={!!Object.keys(validate).length}
                                onButtonClick={this.onClick}>
                {
                    loadedDataFrom !== LoadedDataTypes.SHADOW &&
                    <>
                        {
                            loadedDataFrom === LoadedDataTypes.DYNAMO && !device.Settings.Configuration[ScaleHubCommandTypes.SET_CONFIGURATION].isSet &&
                            <InfoBox
                                boxColor="warning">{i18next.t("newSettings.devices.settingsInfo.dynamo", {date: moment(SetTime).format("DD.MM.YYYY HH:mm")})}</InfoBox>
                        }
                        {
                            loadedDataFrom === LoadedDataTypes.NO_DATA &&
                            <InfoBox boxColor="error">{t("newSettings.devices.settingsInfo.noData")}</InfoBox>
                        }
                    </>
                }
                <Switch id={"sweepWeights"} value={sweepWeights} label={t("newSettings.silo.generalSetup.sweepWeights")}
                        onChange={(value) => this.setValue("sweepWeights", value)}
                />

                <Switch id={"isPin"} value={isPin} label={t("newSettings.silo.generalSetup.isPin")}
                        onChange={(value) => this.setValue("isPin", value)}
                />
                <LabeledInput disabled={!isPin} error={validate.pin} label={t("newSettings.silo.generalSetup.pin")}
                              type={"text"} value={pin}
                              optionalProps={{maxLength: 4, pattern: "[0-9]{4}"}}
                              onChange={(value) => this.setValue("pin", value)}/>
                <Switch id={"flashDisplay"} value={flashDisplay} label={t("newSettings.silo.generalSetup.flashDisplay")}
                        onChange={(value) => this.setValue("flashDisplay", value)}
                />
                <Collapse in={flashDisplay} mountOnEnter unmountOnExit>
                    {this.renderMinWeights()}
                </Collapse>

            </DeviceSettingsCard>
        );
    }

}

GeneralSetup.propTypes = {
    siloses: PropTypes.array.isRequired
};

GeneralSetup = connect(state => ({
    shadows: state.shadows.shadows,
}))(GeneralSetup);

export default withTranslation()(GeneralSetup);
