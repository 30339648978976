import React from "react";
import Card from "../../../../components/basics/card/Card";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import withRoles from "../../../../components/withRoles";
import {Roles} from "../../../../constans/roles";
import {withTranslation} from "react-i18next";
import packageJson from "../../../../../package.json";
import {detect} from "detect-browser";
import Button from "../../../../components/basics/button/Button";
import {db} from './../../../../database/lokiDB';
import Paths from './../../../../api/paths';
import {invokeApig} from "../../../../libs/awsLib";
import {debugClientEnv} from './../../../../api/debug/debugClientEnv';
import {unregisterServiceWorkers} from "../../../../utils/DOMUtils";

const STAGE = process.env.REACT_APP_STAGE || "local";

export class UtilityView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            send: {loading: false, success: undefined}
        }
    }


    callTestAPI = (DtaModTime = 0) => {
        const {user: {ClientID}} = this.props;
        return invokeApig({
            ...Paths.listSettings({
                clientID: ClientID
            }),
            queryParams: {DtaModTime: DtaModTime}
        })
    }

    sendReport = (setLoading = true) => {
        setLoading && this.setState({
            send: {
                loading: true,
                success: undefined
            }
        });
        const {farm, user: {ClientID, LocalUserID}} = this.props;
        const report = {};

        report.localTime = +new Date();
        report.farmId = farm;
        report.clientId = ClientID;
        report.localUserId = LocalUserID;
        try {
            report.browser = JSON.parse(JSON.stringify(detect()));
        } catch (err) {
            report.browser = "Error getting browser"
        }
        report.stage = STAGE;
        report.appBuildTime = process.env.REACT_APP_BUILD_DATE_TIME;
        report.appVersion = packageJson.version;
        try {
            report.collections = db.listCollections();
        } catch (e) {
            report.collections = 'List collections failed'
            report.collectionsError = e.message;
        }
        if (Array.isArray(report.collections)) {
            report.collections.forEach(({name}, i) => {
                try {
                    const col = db.getCollection(name)
                    if (col) {
                        if ("cachedTablesParams" === name) {
                            report.cachedDtaModTime = JSON.parse(JSON.stringify(col.data || []));
                        }
                        report.collections[i].last10Objects = JSON.parse(JSON.stringify((col.data || []).slice(Math.max((col.data || []).length - 10, 0))))
                    } else {
                        report.collections[i].last10Objects = 'Collection missing'
                    }
                } catch (e) {
                    report.collections[i].last10Objects = 'Error getting information'
                    report.collections[i].last10ObjectsError = e.message;
                }
            })
        }
        let DtaModTime = 0;
        if (report.cachedDtaModTime) {
            try {
                DtaModTime = report.cachedDtaModTime.find(o => o.table === "settings").DtaModTime || 0
            } catch (err) {
                DtaModTime = 0;
            }
        } else {
            report.cachedDtaModTime = "Error getting information"
        }
        report.testApiCall1 = {type: "SettingsWithLatestDtaModTime", dtaModTime: DtaModTime}
        this.callTestAPI(DtaModTime).then(res => {
            report.testApiCall1.success = true;
            report.testApiCall1.responseSize = res.length;
        }).catch(err => {
            report.testApiCall1.success = false;
        }).finally(() => {
            report.testApiCall2 = {type: "SettingsWithZeroedDtaModTime", dtaModTime: 0}
            this.callTestAPI(0).then(res => {
                report.testApiCall2.success = true;
                report.testApiCall2.responseSize = res.length;
            }).catch(err => {
                report.testApiCall2.success = false;
            }).finally(() => {
                debugClientEnv(report).then(res => {
                    setLoading && this.setState({
                        send: {
                            loading: false,
                            success: true
                        }
                    });
                }).catch(err => {
                    setLoading && this.setState({
                        send: {
                            loading: false,
                            success: false
                        }
                    });
                })
            })
        })
    }

    deleteIndexedDB = async () => {
        try {
            this.sendReport(false)
        } catch (err) {
            console.error("Error sending report");
        }
        const databases = await indexedDB.databases()
        databases.forEach(database => {
            console.log("deleting->", database.name);
            indexedDB.deleteDatabase(database.name)
        })
        unregisterServiceWorkers();
        location.reload(true);
    }

    render() {
        const {t} = this.props;
        const {send} = this.state;
        return (
            <Card>
                <h4>{t("newSettings.utility.deleteLocalDatabase")}</h4>
                <Button className={"mt-1"} icon={<i className={"fas fa-fw fa-trash"}/>} buttonColor={"error"}
                        type="button" onClick={this.deleteIndexedDB}/>
                <h4>{t("newSettings.utility.sendReport")}</h4>
                <Button className={"mt-1"} isLoading={send.loading} icon={<i
                    className={`fas fa-fw ${send.success === undefined ? "fa-paper-plane" : send.success ? "fa-check" : "fa-exclamation"}`}/>}
                        type="button" onClick={this.sendReport}/>
            </Card>
        );
    }

}

UtilityView = connect(state => ({
    farm: state.location.farm,
    user: state.user.user
}))(UtilityView);

let _UtilityView = withRouter(UtilityView);
_UtilityView = withRoles({roles: [Roles._GENERAL_READ], showComponent: true})(_UtilityView);
export default withTranslation()(_UtilityView);
