import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import Card from "../../../../../components/basics/card/Card";
import List from "../../../../../components/basics/list/List";
import WorktypeSystemScheduleRow from "./WorktypeSystemScheduleRow";
import SystemScheduleWorktype from "../../../../../beans/settings/SystemScheduleWorktype";
import "./_system-schedules.scss"
import SystemScheduleRow from "./SystemScheduleRow";
import SystemScheduleBean from "../../../../../beans/settings/SystemSchedule";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        worktypes: state.settings.scheduleWorktypes,
        schedules: state.settings.systemSchedules,
    };
}

class SystemSchedule extends Component {

    createWorktypesList() {
        const {worktypes} = this.props;
        let array = [];
        for (let i = 0; i < 8; i++) {
            let worktype = worktypes.find(item => item.SetData.Index === i);
            array.push(
                <WorktypeSystemScheduleRow index={i} worktype={worktype}/>
            )
        }
        return array;
    }

    createScheduleList() {
        const {schedules} = this.props;
        let array = [];
        for (let i = 0; i < 15; i++) {
            let schedule = schedules.find(item => item.SetData.Index === i);
            array.push(
                <SystemScheduleRow index={i} schedule={schedule}/>
            )
        }
        return array;
    }

    render() {
        const {t} = this.props;
        return (
            <div className="system-schedules">
                <Card>
                    <h4>{t("newSettings.system.systemSchedule.mainView.workTypes")}</h4>
                    <List>
                        {this.createWorktypesList()}
                    </List>
                </Card>
                <Card>
                    <h4>{t("schedules")}</h4>
                    <List>
                        {this.createScheduleList()}
                    </List>
                </Card>
            </div>
        );
    }

}

SystemSchedule.propTypes = {
    worktypes: PropTypes.arrayOf(PropTypes.instanceOf(SystemScheduleWorktype)).isRequired,
    schedules: PropTypes.arrayOf(PropTypes.instanceOf(SystemScheduleBean)).isRequired,
};

SystemSchedule = connect(
    mapStateToProps,
)(SystemSchedule);

export default withTranslation()(SystemSchedule);