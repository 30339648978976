import settingsDB from "../../../../database/settingsDB";
import {createSettingDynamoDB, updateSettingDynamoDB} from "../../../../actions/settingsAction";
import Setting from "../../../../beans/Setting";
import {SettingTypes} from "../../../../constans/settingTypes";
import _ from "lodash";

export function submit(values, dispatch, props) {
    const {showAliases, offline, RFIDReader} = values;
    const {user: {ClientID}} = props;
    let general = settingsDB.getSettingByID(ClientID);
    let data = {
        ShowAliases: showAliases,
        Offline: offline,
        RFIDReader: RFIDReader
    };
    console.log(data);
    if (general) {
        let clone = _.cloneDeep(general);
        if (!clone.SetData) {
            clone.SetData = {Settings: {}};
        } else {
            if (!clone.SetData.Settings) {
                clone.SetData = {...clone.SetData, Settings: {}}
            }
        }
        clone.SetData.Settings.Devices = data;
        clone.DtaModTime = +new Date();
        console.log("update - >", clone);
        dispatch(updateSettingDynamoDB(clone));
    } else {
        let setting = Setting.createSetting(SettingTypes.GENERAL, {Settings: {Devices: data}});
        console.log("create - >", setting);
        dispatch(createSettingDynamoDB(setting));
    }
}