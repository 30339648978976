import React, {Component} from 'react';
import {connect} from 'react-redux';
import Card from "../../../../../components/basics/card/Card";
import {Field, FormSection, reduxForm} from "redux-form";
import ReduxLabeledInput from "../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import {Col, Row} from "react-bootstrap";
import ReduxLabeledSlider from "../../../../../components/basics/slider/labeled-slider/ReduxLabeledSlider";
import {submit, validate} from "./AddOrEditWorktypeSystemScheduleSubmit"
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../../components/basics/button/Button";
import {timeFormatter} from "../../../../../utils/DateTimeUtils";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        worktypes: state.settings.scheduleWorktypes,
        farm: state.location.farm,
    };
}

class AddOrEditWorktypeSystemSchedule extends Component {

    constructor(props) {
        super(props);

        const {match: {params: {index, SetID}}, worktypes} = this.props;
        if (SetID) {
            let worktype = worktypes.find(item => item.SetID === SetID);
            this.props.initialize(worktype);
        } else {
            this.props.initialize({
                SetData: {
                    Index: +index,
                    WorkTime: 1000,
                    StopTime: 1000
                }
            })
        }
    }

    render() {
        const {handleSubmit, t} = this.props;
        return (
            <Card>
                <form onSubmit={handleSubmit}>
                    <FormSection name={"SetData"}>
                        <Field
                            name="Name"
                            component={ReduxLabeledInput}
                            label={t("designation")}
                            type={"text"}
                        />
                        <Row>
                            <Col md={6}>
                                <Field
                                    name="WorkTime"
                                    component={ReduxLabeledSlider}
                                    label={t("workTime")}
                                    min={1000}
                                    max={1000 * 60 * 10}
                                    step={1000}
                                    valueFormatter={timeFormatter}
                                />
                            </Col>
                            <Col md={6}>
                                <Field
                                    name="StopTime"
                                    component={ReduxLabeledSlider}
                                    label={t("stopTime")}
                                    min={1000}
                                    max={1000 * 60 * 10}
                                    step={1000}
                                    valueFormatter={timeFormatter}
                                />
                            </Col>
                        </Row>
                    </FormSection>
                    <ButtonGroup fixed renderInPortal={false}>
                        <Button buttonStyle={"round"} buttonColor={"success"} icon={<i className="fas fa-save"/>}/>
                    </ButtonGroup>
                </form>
            </Card>
        );
    }

}

AddOrEditWorktypeSystemSchedule = reduxForm({
    form: "addOrEditWorktypeSystemSchedule",
    onSubmit: submit,
    validate
})(AddOrEditWorktypeSystemSchedule);

AddOrEditWorktypeSystemSchedule = connect(
    mapStateToProps,
)(AddOrEditWorktypeSystemSchedule);

export default withTranslation()(AddOrEditWorktypeSystemSchedule);