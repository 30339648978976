import React from "react";
import {Col, Row} from "react-bootstrap";
import {Field, formValueSelector, initialize, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import ReduxLabeledSelect from "../../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import {submit, validate} from "./UnitAndTimeSystemSubmit"
import FormForSettings from "../../FormForSettings";
import Card from "../../../../components/basics/card/Card";
import {getAnimalUnit, getCageUnit, getFeedingUnit, getScaleUnit, getUnitSystem} from "../../../../utils/SettingsUtils";
import withRoles from "../../../../components/withRoles";
import {Roles} from "../../../../constans/roles";
import {UnitTypes} from "../../../../constans/unitTypes";
import {getUnit} from "../../../../utils/UnitUtils";
import {withTranslation} from "react-i18next";
// import moment from "moment-timezone";

const FormName = 'settings-system-unit-and-time';

export class UnitAndTimeSystemView extends React.Component {

    constructor(props) {
        super(props);
        const {dispatch} = this.props;
        dispatch(initialize(FormName, {
            // timezone: moment.tz.guess() || "Europe/Warsaw",
            unitSystem: getUnitSystem(),
            feedingUnit: getFeedingUnit(),
            cageUnit: getCageUnit(),
            scaleUnit: getScaleUnit(),
            animalUnit: getAnimalUnit(),
        }))
    }

    render() {
        const {handleSubmit, t, unit} = this.props;
        // const timezoneOptions = moment.tz.names().map(tzone => ({
        //     name: tzone,
        //     value: tzone
        // }));
        const unitWeightOptions = (from, to) => [
            {
                name: getUnit("weight", UnitTypes.SMALL, {overrideUnitSystem: unit}),
                value: UnitTypes.SMALL
            },
            {
                name: getUnit("weight", UnitTypes.MEDIUM, {overrideUnitSystem: unit}),
                value: UnitTypes.MEDIUM
            },
            {
                name: getUnit("weight", UnitTypes.BIG, {overrideUnitSystem: unit}),
                value: UnitTypes.BIG
            }
        ].slice(from, to+1);
        const unitOptions =
            [
                {
                    name: t('newSettings.system.unitAndTimeSystem.imperial'),
                    value: "imperial"
                },
                {
                    name: t("newSettings.system.unitAndTimeSystem.metric"),
                    value: "metric"
                }
            ];
        return (
            <Card className={FormName}>
                <FormForSettings handleSubmit={handleSubmit}>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <Field
                                id="unitSystem"
                                name="unitSystem"
                                component={ReduxLabeledSelect}
                                label={t("newSettings.system.unitAndTimeSystem.unitSystem")}
                                options={unitOptions}
                            />
                            <Field
                                id="animalUnit"
                                name="animalUnit"
                                component={ReduxLabeledSelect}
                                label={t("animals")}
                                options={unitWeightOptions(0,1)}
                            />
                            <Field
                                id="feedingUnit"
                                name="feedingUnit"
                                component={ReduxLabeledSelect}
                                label={t("newSettings.system.unitAndTimeSystem.feedingUnit")}
                                options={unitWeightOptions(0,1)}
                            />
                            <Field
                                id="cageUnit"
                                name="cageUnit"
                                component={ReduxLabeledSelect}
                                label={t("newSettings.system.unitAndTimeSystem.cageUnit")}
                                options={unitWeightOptions(0,1)}
                            />
                            <Field
                                id="scaleUnit"
                                name="scaleUnit"
                                component={ReduxLabeledSelect}
                                label={t("weightPanels")}
                                options={unitWeightOptions(1,2)}
                            />
                            {/*<Field*/}
                            {/*    id="timezone"*/}
                            {/*    name="timezone"*/}
                            {/*    component={ReduxLabeledSelect}*/}
                            {/*    label={content.timezone}*/}
                            {/*    options={timezoneOptions}*/}
                            {/*/>*/}
                        </Col>
                    </Row>
                </FormForSettings>
            </Card>
        );
    }

}

UnitAndTimeSystemView = reduxForm({
    form: FormName,
    onSubmit: submit,
    validate
})(UnitAndTimeSystemView);
const selector = formValueSelector(FormName);
UnitAndTimeSystemView = connect(state => ({
    farm: state.location.farm,
    user: state.user.user,
    unit: selector(state, "unitSystem")
}))(UnitAndTimeSystemView);

UnitAndTimeSystemView = withTranslation()(UnitAndTimeSystemView);

export default withRoles({
    roles: [Roles._BILLING],
    showComponent: true
})(UnitAndTimeSystemView);
