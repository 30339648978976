import SystemScheduleWorktype from "../../../../../beans/settings/SystemScheduleWorktype";
import {createSettingDynamoDB, updateSettingDynamoDB} from "../../../../../actions/settingsAction";

export function submit(values, dispatch, props) {
    let edit = props.match.params.index === undefined;
    if (edit) {
        let worktype = new SystemScheduleWorktype(values).prepareBeanToSave();
        worktype.setDtaModTime();
        dispatch(updateSettingDynamoDB(worktype));
    } else {
        const {SetData: {Name, WorkTime, StopTime, Index}} = values;
        let worktype = SystemScheduleWorktype.createSystemScheduleWorktype(Name, WorkTime, StopTime, Index);
        dispatch(createSettingDynamoDB(worktype));
    }
    const {farm} = props;
    props.history.push(`/${farm}/settings/system/schedules`)
}

export function validate(values, props) {
    const errors = {
        SetData: {

        }
    };
    const {t} = props;

    if (values.SetData) {
        if (!values.SetData.Name) {
            errors.SetData.Name = t("required");
        }
    } else {
        errors.SetData = {
            Name: t("required")
        }
    }

    return errors;
}