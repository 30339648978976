import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Input from "../../../../../components/basics/input/Input";
import "./_destination-data.scss"

class DestinationDataComponent extends Component {

    state = {
        value: this.props.value,
        changed: false
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.changed && prevState.value !== nextProps.value) {
            return {
                value: nextProps.value,
                changed: false
            }
        }
        return null;
    }

    onSave = () => {
        this.setState({
            changed: false
        })
    }

    sendChangeToAPI = () => {
        const {lang, path} = this.props;
        const {value} = this.state;
        this.props.addToQueue({lang, path, value}, this.onSave);
    };

    addInsert(value, insert) {
        if (!value.includes(insert)) {
            // sprawdza czy nie isnieje czesc string, np {1 lub {cou, bez sprawdzania pojedynczych klamr
            for (let i = insert.length - 1; i >= 3; i--) {
                let replaced = value.replace(insert.slice(0, i), insert);
                if (replaced !== value) {
                    return replaced;
                }
            }
            return value + insert;
        }
        return value;
    }

    onChange = value => {
        for (let insert of this.props.inserts) {
            value = this.addInsert(value, insert);
        }
        this.setState({
            value,
            changed: true
        });
    }

    getClassName() {
        const {sourceValue} = this.props;
        const {value} = this.state;
        let className = "destination";
        if (value === sourceValue) className += " equal";
        if (!value) className += " empty";
        return className;
    }

    render() {
        const {lang} = this.props;
        const {value} = this.state;
        return (
            <Input type={"textarea"} value={value} className={this.getClassName()} lang={lang}
                   onChange={this.onChange} onBlur={this.sendChangeToAPI}/>
        );
    }
}

DestinationDataComponent.propTypes = {
    value: PropTypes.string,
    index: PropTypes.number.isRequired,
    lang: PropTypes.string.isRequired,
    sourceValue: PropTypes.string,
    path: PropTypes.string.isRequired,
    addToQueue: PropTypes.func.isRequired,
    inserts: PropTypes.arrayOf(PropTypes.string),
};

export default DestinationDataComponent;