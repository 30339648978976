import React from "react";
import Loading from "../../../../components/loading/LoadingComponent";
import {Col, Row} from "react-bootstrap";
import {connect} from "react-redux";
import NewIOT from "../../../../IOT/NewIOT";
import GeneralSetup from "./settings/GeneralSetup";
import Tare from "./settings/Tare";
import Scale from "./settings/Scale";

export class ManageSiloConfiguration extends React.Component {

    state = {
        selectedSiloses: this.props.location.state ? this.props.location.state.selectedSiloses : [],
        loading: true
    };

    constructor(props) {
        super(props);

        if (this.state.selectedSiloses.length === 0) {
            this.props.history.push(`/${this.props.farm}/settings/weightPanels/manage`)
        }
        NewIOT.startSendingDeviceState(this.state.selectedSiloses, this.setLoading, this.setLoading);
    }

    setLoading = () => {
        this.setState({
            loading: false
        })
    };

    render() {
        const {selectedSiloses, loading} = this.state;
        if (selectedSiloses.length === 0) return null;
        return (
            <div style={{position: "relative"}}>
                <Loading isLoading={loading} /*timeout={10} onTimeout={()=>this.props.history.push("/")}*//>
                <Row>
                    <Col xl={12}>
                        <GeneralSetup siloses={selectedSiloses}/>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6}>
                        <Tare siloses={selectedSiloses}/>
                    </Col>
                    <Col xl={6}>
                        <Scale siloses={selectedSiloses}/>
                    </Col>
                </Row>
            </div>
        );
    }

}

ManageSiloConfiguration = connect(state => ({
    farm: state.location.farm
}))(ManageSiloConfiguration);

export default ManageSiloConfiguration;