import settingsDB from "../../../../database/settingsDB";
import _ from "lodash";
import {createSettingDynamoDB, updateSettingDynamoDB} from "../../../../actions/settingsAction";
import Setting from "../../../../beans/Setting";
import {SettingTypes} from "../../../../constans/settingTypes";

export function submit(values, dispatch, props) {
    console.log(values);
    const {unitSystem, feedingUnit, cageUnit, scaleUnit, animalUnit} = values;
    const {user: {ClientID}} = props;
    let general = settingsDB.getSettingByID(ClientID);
    let data = {
        UnitSystem: unitSystem,
        FeedingUnit: feedingUnit,
        CageUnit: cageUnit,
        ScaleUnit: scaleUnit,
        AnimalUnit: animalUnit,
    };
    if (general) {
        let clone = _.cloneDeep(general);
        if (!clone.SetData) {
            clone.SetData = {Settings: {}};
        } else {
            if (!clone.SetData.Settings) {
                clone.SetData = {...clone.SetData, Settings: {}}
            }
        }
        clone.SetData.Settings.System = data;
        clone.DtaModTime = +new Date();
        console.log("update - >", clone);
        dispatch(updateSettingDynamoDB(clone));
    } else {
        let setting = Setting.createSetting(SettingTypes.GENERAL, {Settings: {System: data}});
        console.log("create - >", setting);
        dispatch(createSettingDynamoDB(setting));
    }
}

export function validate(values, props) {
    const errors = {};
    const {unitSystem} = values;
    if (!unitSystem) {
        errors.unitSystem = props.t("required");
    }
    return errors;
}