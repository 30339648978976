import {checkIfTimeRangeOverlaps} from "../../../../../utils/DateTimeUtils";
import {createSettingDynamoDB, updateSettingDynamoDB} from "../../../../../actions/settingsAction";
import SystemSchedule from "../../../../../beans/settings/SystemSchedule";

export function submit(values, dispatch, props) {
    let edit = props.match.params.index === undefined;
    if (edit) {
        let schedule = new SystemSchedule(values).prepareBeanToSave();
        schedule.setDtaModTime();
        dispatch(updateSettingDynamoDB(schedule));
    } else {
        const {SetData: {Name, Intervals, Index}} = values;
        let schedule = SystemSchedule.createSystemSchedule(Name, Intervals, Index);
        dispatch(createSettingDynamoDB(schedule));
    }
    const {farm} = props;
    props.history.push(`/${farm}/settings/system/schedules`)
}

export function validate(values, props) {
    const errors = {
        SetData: {}
    };
    const {t} = props;

    if (values.SetData) {
        if (!values.SetData.Name) {
            errors.SetData.Name = t("required");
        }

        if (values.SetData.Intervals) {
            errors.SetData.Intervals = values.SetData.Intervals.map((interval, index) => {
                const intervalError = {};

                if (interval.StartTime === undefined) {
                    intervalError.StartTime = t("required");
                }

                if (interval.StopTime === undefined) {
                    intervalError.StopTime = t("required");
                }

                if (!interval.WorkType) {
                    intervalError.WorkType = t("required");
                }

                if (interval.StartTime !== undefined && interval.StopTime !== undefined) {
                    let other = values.SetData.Intervals.filter((item, i) => item.StartTime !== undefined && item.StopTime !== undefined && i !== index);
                    let overlaps = false;
                    for (let row of other) {
                        if(checkIfTimeRangeOverlaps(interval.StartTime, interval.StopTime, row.StartTime, row.StopTime)) {
                            overlaps = true;
                        }
                    }
                    if (overlaps) {
                        intervalError.StartTime = t("errors.duplicate");
                        intervalError.StopTime = t("errors.duplicate");
                    }

                    if (interval.StopTime < interval.StartTime) {
                        intervalError.StartTime = t("errors.mustBeBelow") + "czas zatrzymania";
                        intervalError.StopTime = t("errors.mustBeAbove") + "czas startu";
                    }
                }

                return intervalError;
            });
        }
    }

    return errors;
}