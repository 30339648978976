import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import ListItem from "../../../../../components/basics/list/list-item/ListItem";
import SystemScheduleWorktype from "../../../../../beans/settings/SystemScheduleWorktype";
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../../components/basics/button/Button";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {ModalName as ConfirmModalName} from "../../../../../components/modals-new/confirm-modal/ConfirmModal";
import {updateSettingDynamoDB} from "../../../../../actions/settingsAction";
import {timeFormatter} from "../../../../../utils/DateTimeUtils";
import {setScheduleOutputMode} from "../../../../../IOT/device-functions/GatewayFunctions";
import {DevType} from "../../../../../constans/devices";
import Gateway from "../../../../../beans/devices/Gateway";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        gateways: state.farmDevices.devices.filter(item => item.DevType === DevType.GATEWAY),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch)
    }
}

class WorktypeSystemScheduleRow extends Component {

    onRemoveClick = () => {
        const {worktype, t} = this.props;
        this.props.show(ConfirmModalName, {
            title: t("newSettings.system.systemSchedule.worktypeRow.removeTitle"),
            text: i18next.t("newSettings.system.systemSchedule.worktypeRow.removeText", {type: worktype.SetData.Name}),
            confirmText: t("yes"),
            onConfirmed: props => {
                let clone = worktype.prepareBeanToSave();
                clone.DtaDelTime = new Date().getTime();
                clone.setDtaModTime();
                this.props.dispatch(updateSettingDynamoDB(clone));
                props.handleHide();
            }
        })
    };

    onUpdateOnDevicesClick = () => {
        const {worktype, gateways} = this.props;
        let object = worktype.getBackendObject();
        for (let gateway of gateways) {
            setScheduleOutputMode(gateway, object);
        }
    };

    render() {
        const {worktype, index, farm, t} = this.props;
        return (
            <ListItem>
                <span>
                    <h3>{index + 1}</h3>
                </span>
                {
                    worktype &&
                    <>
                        <span>
                            <div>
                                <strong>{worktype.SetData.Name}</strong>
                            </div>
                            <small>{t("designation")}</small>
                        </span>
                        <span>
                            <div>
                                <strong>{timeFormatter(worktype.SetData.WorkTime)}</strong>
                            </div>
                            <small>{t("workTime")}</small>
                        </span>
                        <span>
                            <div>
                                <strong>{timeFormatter(worktype.SetData.StopTime)}</strong>
                            </div>
                            <small>{t("stopTime")}</small>
                        </span>
                        <ButtonGroup>
                            <Link to={`/${farm}/settings/system/schedules/worktype/edit/${worktype.SetID}`}>
                                <Button buttonStyle={"round"} icon={<i className="fas fa-edit"/>}/>
                            </Link>
                            <Button type={"button"} icon={<i className="fas fa-share"/>} buttonStyle={"round"}
                                    onClick={this.onUpdateOnDevicesClick}/>
                            <Button buttonColor={"error"} buttonStyle={"round"} icon={<i className="fas fa-trash"/>}
                                    onClick={this.onRemoveClick}/>
                        </ButtonGroup>
                    </>
                }
                {
                    !worktype &&
                    <>
                        <span className="opacity-50" style={{flexGrow: 6}}>
                            <h5 className="justify-content-center">{t("newSettings.system.systemSchedule.worktypeRow.notConfigured")}</h5>
                        </span>
                        <ButtonGroup>
                            <Link to={`/${farm}/settings/system/schedules/worktype/add/${index}`}>
                                <Button buttonColor={"success"} buttonStyle={"round"}
                                        icon={<i className="fas fa-plus"/>}/>
                            </Link>
                        </ButtonGroup>
                    </>
                }
            </ListItem>
        );
    }

}

WorktypeSystemScheduleRow.propTypes = {
    worktype: PropTypes.instanceOf(SystemScheduleWorktype),
    index: PropTypes.number.isRequired,
    farm: PropTypes.string.isRequired,
    gateways: PropTypes.arrayOf(PropTypes.instanceOf(Gateway)),
};

WorktypeSystemScheduleRow = connect(
    mapStateToProps,
    mapDispatchToProps
)(WorktypeSystemScheduleRow);

export default withTranslation()(WorktypeSystemScheduleRow);