import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import ListItem from "../../../../../components/basics/list/list-item/ListItem";
import SystemSchedule from "../../../../../beans/settings/SystemSchedule";
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import {Link} from "react-router-dom";
import Button from "../../../../../components/basics/button/Button";
import {ModalName as ConfirmModalName} from "../../../../../components/modals-new/confirm-modal/ConfirmModal";
import {updateSettingDynamoDB} from "../../../../../actions/settingsAction";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {DevType} from "../../../../../constans/devices";
import Gateway from "../../../../../beans/devices/Gateway";
import {setScheduleOutput} from "../../../../../IOT/device-functions/GatewayFunctions";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        gateways: state.farmDevices.devices.filter(item => item.DevType === DevType.GATEWAY),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch)
    }
}

class SystemScheduleRow extends Component {

    onRemoveClick = () => {
        const {schedule, t} = this.props;
        this.props.show(ConfirmModalName, {
            title: t("newSettings.system.systemSchedule.scheduleRow.removeTitle"),
            text: i18next.t("newSettings.system.systemSchedule.scheduleRow.removeText", {type: schedule.SetData.Name}),
            confirmText: t("yes"),
            onConfirmed: props => {
                let clone = schedule.prepareBeanToSave();
                clone.DtaDelTime = new Date().getTime();
                clone.setDtaModTime();
                this.props.dispatch(updateSettingDynamoDB(clone));
                props.handleHide();
            }
        })
    };

    onUpdateOnDevicesClick = () => {
        const {schedule, gateways} = this.props;
       let object = schedule.getBackendObject();
       for (let gateway of gateways) {
           setScheduleOutput(gateway, object);
       }
    };

    render() {
        const {index, schedule, farm, t} = this.props;
        return (
            <ListItem>
                <span>
                    <h3>{index + 1}</h3>
                </span>
                {
                    schedule &&
                    <>
                        <span>
                            <div>
                                <strong>{schedule.SetData.Name}</strong>
                            </div>
                            <small>{t("designation")}</small>
                        </span>
                        <span>
                            <div>
                                <strong>{schedule.SetData.Intervals.length}</strong>
                            </div>
                            <small>{t("newSettings.system.systemSchedule.scheduleRow.numberOfIntervals")}</small>
                        </span>
                        <ButtonGroup>
                            <Link to={`/${farm}/settings/system/schedules/edit/${schedule.SetID}`}>
                                <Button buttonStyle={"round"} icon={<i className="fas fa-edit"/>}/>
                            </Link>
                            <Button type={"button"} icon={<i className="fas fa-share"/>} buttonStyle={"round"}
                                    onClick={this.onUpdateOnDevicesClick}/>
                            <Button buttonColor={"error"} buttonStyle={"round"} icon={<i className="fas fa-trash"/>}
                                    onClick={this.onRemoveClick}/>
                        </ButtonGroup>
                    </>
                }
                {
                    !schedule &&
                    <>
                        <span className="opacity-50" style={{flexGrow: 6}}>
                            <h5 className="justify-content-center">{t("newSettings.system.systemSchedule.scheduleRow.notConfigured")}</h5>
                        </span>
                        <ButtonGroup>
                            <Link to={`/${farm}/settings/system/schedules/add/${index}`}>
                                <Button buttonColor={"success"} buttonStyle={"round"}
                                        icon={<i className="fas fa-plus"/>}/>
                            </Link>
                        </ButtonGroup>
                    </>
                }
            </ListItem>
        );
    }

}

SystemScheduleRow.propTypes = {
    index: PropTypes.number.isRequired,
    schedule: PropTypes.instanceOf(SystemSchedule),
    farm: PropTypes.string,
    gateways: PropTypes.arrayOf(PropTypes.instanceOf(Gateway)),
};

SystemScheduleRow = connect(
    mapStateToProps,
    mapDispatchToProps
)(SystemScheduleRow);

export default withTranslation()(SystemScheduleRow);