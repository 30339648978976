import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import PropTypes from "prop-types";
import {setScale} from "../../../../../IOT/device-functions/ScaleHubFunctions";
import _ from "lodash";
import {connect} from "react-redux";
import LabeledInput from "../../../../../components/basics/input/labeled-input/LabeledInput";
import Button from "../../../../../components/basics/button/Button";
import {convertWeightUnitTo} from "../../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../../constans/unitTypes";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
//todo: chart zamula i nie zmienia wartosci po zaladowaniu, dodac dane takie jak: kiedy sie skonczy pasza, kiedy bylo napelniane itd
const SilosesNumber = 8;

const defaultValues = {
    scale: new Array(SilosesNumber).fill(0),
};

export class Scale extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ...defaultValues,
            changed: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!this.state.changed) {
            this.setState({
                ...this.getScaleValues(nextProps)
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState) || !_.isEqual(this.props.t("newSettings.silo.scale"), nextProps.t("newSettings.silo.scale"));
    }

    getScaleValues = (props = this.props) => {
        const {siloses, shadows} = props;
        let scales = _.get(shadows.get(siloses[0].DevID), "scales");
        return {
            scale: scales ? scales.map(s => s.value) : defaultValues.scale
        }
    };

    getWeight = (props, index) => {
        const {siloses, shadows} = props;
        let shadow = shadows.get(siloses[0].DevID);
        return _.get(shadow, `weights[${index}].value`);
    };

    setAllScales = () => {
        const {siloses} = this.props;
        const {scale} = this.state;
        const valid = !Object.keys(this.validate()).length;
        //todo: dorobic wysylanie na wiele urzadzen jak backend bedzie to umial robic
        if (valid) {
            siloses.forEach(device => {
                new Array(SilosesNumber).fill(1).forEach((o, index) => {
                    setScale(device, {
                        number: index,
                        value: +scale[index]
                    })
                })

            })
        }
    };

    setSingleScale = (index) => {
        const valid = !_.get(this.validate(), `scale[${index}]`);
        const {siloses} = this.props;
        const {scale} = this.state;
        if (valid) {
            siloses.forEach(device => {
                setScale(device, {
                    number: index,
                    value: +scale[index]
                })
            })
        }
    };

    setValue = (key, value) => {
        this.setState(state => {
            let cloned = _.cloneDeep(state);
            _.set(cloned, key, value);
            return {
                ...cloned,
                changed: true
            }
        })
    };

    validate = () => {
        let errors = {};
        const {scale} = this.state;
        const {t} = this.props;
        scale.forEach((sc, index) => {
            if (_.isNil(sc) || sc === "" || !_.isNumber(+sc)) {
                _.set(errors, `scale[${index}]`, t("required"));
            }
        });

        return errors
    };

    renderScales = () => {
        const {
            scale
        } = this.state;
        let validate = this.validate();
        return (
            <>
                {
                    new Array(SilosesNumber).fill(1).map((member, index) => (
                        <div>
                            <LabeledInput className="make-room-for-button" error={_.get(validate, `scale[${index}]`)}
                                          label={i18next.t("newSettings.silo.scale.header", {
                                              type: index + 1,
                                              amount: convertWeightUnitTo(this.getWeight(this.props, index), {
                                              showUnit: true,
                                              unit: UnitTypes.MEDIUM,
                                              fixed: 0
                                              })
                                          })} type={"number"} value={scale[index]}
                                          onChange={(value) => this.setValue(`scale[${index}]`, value)}/>
                            <Button buttonStyle={"round"} type="button"
                                    disabled={_.get(validate, `scale[${index}]`)}
                                    onClick={() => this.setSingleScale(index)}
                                    icon={<i className={"fas fa-save"}/>}/>
                        </div>
                    ))
                }
            </>)

    };

    render() {
        const {t} = this.props;
        return (
            <>
                <DeviceSettingsCard title={t("newSettings.silo.scale.title")} onButtonClick={this.setAllScales}>
                    {this.renderScales()}
                </DeviceSettingsCard>
            </>
        );
    }
}

Scale.propTypes = {
    siloses: PropTypes.array.isRequired
};

Scale = connect(state => ({
    shadows: state.shadows.shadows,
}))(Scale);

export default withTranslation()(Scale);