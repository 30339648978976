import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import Card from "../../../../../components/basics/card/Card";
import {Field, FieldArray, FormSection, propTypes, reduxForm} from "redux-form";
import ReduxLabeledInput from "../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import Button from "../../../../../components/basics/button/Button";
import {Col, Row} from "react-bootstrap";
import moment from "moment";
import ReduxLabeledSelect from "../../../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import {submit, validate} from "./AddOrEditSystemScheduleSubmit"
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import SystemScheduleWorktype from "../../../../../beans/settings/SystemScheduleWorktype";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

function mapStateToProps(state) {
    return {
        worktypes: state.settings.scheduleWorktypes,
        farm: state.location.farm,
        schedules: state.settings.systemSchedules,
    };
}

class AddOrEditSystemSchedule extends Component {

    constructor(props) {
        super(props);

        const {match: {params: {index, SetID}}, schedules} = this.props;
        if (SetID) {
            let schedule = schedules.find(item => item.SetID === SetID);
            this.props.initialize(schedule);
        } else {
            this.props.initialize({
                SetData: {
                    Intervals: [{
                        StartTime: moment.utc(0).minutes(15).toDate().getTime(),
                        StopTime: moment.utc(0).hour(23).minutes(45).toDate().getTime()
                    }],
                    Index: +index
                }
            })
        }
    }

    formatTime = value => {
        if (!value) return null;
        return moment.utc(value).format("HH:mm");
    };

    parseTime = value => {
        if (!value) return null;
        let time = moment.utc(0);
        let split = value.split(":");
        time.hour(+split[0]).minutes(+split[1]);
        return time.toDate().getTime();
    };

    renderIntervals = ({fields}) => (
        <>
            {
                fields.map((member, index) =>
                    <Card>
                        <h5 className="flex-lg-row justify-content-between align-content-center">
                            {i18next.t("newSettings.system.systemSchedule.addSystemSchedule.interval", {type: index + 1})}
                            <Button
                                onClick={() => fields.remove(index)} type={"button"}
                                buttonStyle={"round"} className={"text"}
                                icon={<i className={"fas fa-trash"}/>} disabled={fields.length === 1}/>
                        </h5>
                        <Row>
                            <Col xs={4}>
                                <Field
                                    name={`${member}.StartTime`}
                                    id={`${member}.StartTime`}
                                    label={this.props.t("newSettings.system.systemSchedule.addSystemSchedule.startTime")}
                                    type={"time"}
                                    format={this.formatTime}
                                    parse={this.parseTime}
                                    component={ReduxLabeledInput}
                                />
                            </Col>
                            <Col xs={4}>
                                <Field
                                    name={`${member}.StopTime`}
                                    id={`${member}.StopTime`}
                                    label={this.props.t("newSettings.system.systemSchedule.addSystemSchedule.stopTime")}
                                    type={"time"}
                                    format={this.formatTime}
                                    parse={this.parseTime}
                                    component={ReduxLabeledInput}
                                />
                            </Col>
                            <Col xs={4}>
                                <Field
                                    name={`${member}.WorkType`}
                                    component={ReduxLabeledSelect}
                                    options={this.props.worktypes.map(worktype => ({
                                        name: worktype.getSelectName(),
                                        value: worktype.SetID
                                    }))}
                                    label={this.props.t("worktype")}
                                    clearButton={false}
                                />
                            </Col>
                        </Row>
                    </Card>
                )
            }
            {
                fields.length <= 32 &&
                <Card>
                    <h5 className="flex-lg-row justify-content-between align-content-center pointer"
                        onClick={() => fields.push({})}>
                        {this.props.t("newSettings.system.systemSchedule.addSystemSchedule.addNext")} <i
                        className={"fas fa-plus"}/>
                    </h5>
                </Card>
            }
        </>
    )

    render() {
        const {handleSubmit, t} = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <FormSection name={"SetData"}>
                    <Card>
                        <Field
                            name={"Name"}
                            component={ReduxLabeledInput}
                            label={t("designation")}
                            type={"text"}
                        />
                    </Card>
                    <FieldArray name={"Intervals"} component={this.renderIntervals}/>
                </FormSection>
                <ButtonGroup fixed renderInPortal={false}>
                    <Button buttonColor={"success"} buttonStyle={"round"} icon={<i className="fas fa-save"/>}/>
                </ButtonGroup>
            </form>
        );
    }
}

AddOrEditSystemSchedule.propTypes = {
    ...propTypes,
    worktypes: PropTypes.instanceOf(SystemScheduleWorktype).isRequired,
};

AddOrEditSystemSchedule = reduxForm({
    form: "addOrEditSystemSchedule",
    onSubmit: submit,
    validate
})(AddOrEditSystemSchedule);


AddOrEditSystemSchedule = connect(
    mapStateToProps,
)(AddOrEditSystemSchedule);

export default withTranslation()(AddOrEditSystemSchedule)