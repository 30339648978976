import React from "react";
import {Field, reduxForm} from "redux-form";
import FormForSettings from "../../FormForSettings";
import Card from "../../../../components/basics/card/Card";
import ReduxSwitch from "../../../../components/basics/switch/ReduxSwitch";
import {Col, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {submit} from "./DevicesSystemViewSubmit";
import {getOffline, getRFIDTagReader, getShowAliases} from "../../../../utils/SettingsUtils";
import Button from "../../../../components/basics/button/Button";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";

export class DevicesSystemView extends React.Component {

    constructor(props) {
        super(props);

        const {dispatch, initialize} = this.props;
        dispatch(initialize({
            showAliases: getShowAliases(),
            offline: getOffline(),
            RFIDReader: getRFIDTagReader()
        }))
    }

    showCertInfo = () => {
        this.props.history.push("/acceptCert")
    }

    render() {
        const {handleSubmit, t} = this.props;
        return (
            <Card>
                <FormForSettings handleSubmit={handleSubmit}
                                 optionalButtons={<Button buttonColor="info" buttonStyle={"round"}
                                                          icon={<i className="fas fa-info"/>}
                                                          onClick={this.showCertInfo}/>}>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <div>
                                <Field
                                    name="showAliases"
                                    component={ReduxSwitch}
                                    label={t("newSettings.system.devicesSystem.showAliases")}
                                    id="showAliases"
                                />
                            </div>
                            <div>
                                <Field
                                    name="offline"
                                    id="offline"
                                    component={ReduxSwitch}
                                    label={t("newSettings.system.devicesSystem.offline")}
                                />
                            </div>
                            <div>
                                <Field
                                    name="RFIDReader"
                                    id="RFIDReader"
                                    component={ReduxSwitch}
                                    label={t("newSettings.system.devicesSystem.rfidReader")}
                                />
                            </div>
                        </Col>
                    </Row>
                </FormForSettings>
            </Card>
        );
    }

}

DevicesSystemView = reduxForm({
    form: "devices-system-view",
    onSubmit: submit
})(DevicesSystemView);

export let _DevicesSystemView = connect(state => ({
    user: state.user.user,
}))(DevicesSystemView);
DevicesSystemView = withRouter(_DevicesSystemView);

export default withTranslation()(DevicesSystemView);