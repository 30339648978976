import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import PropTypes from "prop-types";
import {setTare} from "../../../../../IOT/device-functions/ScaleHubFunctions";
import _ from "lodash";
import {connect} from "react-redux";
import LabeledInput from "../../../../../components/basics/input/labeled-input/LabeledInput";
import Button from "../../../../../components/basics/button/Button";
import {convertWeightToBaseUnit, convertWeightUnitTo, getUnit} from "../../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../../constans/unitTypes";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

const SilosesNumber = 8;

const TareUnit = UnitTypes.MEDIUM;

const defaultValues = {
    tare: new Array(SilosesNumber).fill(0),
};

export class Tare extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ...defaultValues,
            changed: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!this.state.changed) {
            this.setState({
                ...this.getTareValues(nextProps)
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState) || !_.isEqual(this.props.t("newSettings.silo.tare"), nextProps.t("newSettings.silo.tare"));
    }

    getTareValues = (props = this.props) => {
        const {siloses, shadows} = props;
        let tares = _.get(shadows.get(siloses[0].DevID), "tares");
        return {
            tare: tares ? tares.map(t => convertWeightUnitTo(t.value, {unit: UnitTypes.MEDIUM, rawValue: true})) : defaultValues.tare
        }
    };

    getValues = (props, index) => {
        const {siloses, shadows} = props;
        let shadow = shadows.get(siloses[0].DevID);
        return {
            weight: _.get(shadow, `weights[${index}].value`),
            rawWeight: _.get(shadow, `weightsRaw[${index}].value`),
        }
    };

    setAllTares = () => {
        const {siloses} = this.props;
        const {tare} = this.state;
        const valid = !Object.keys(this.validate()).length;
        //todo: dorobic wysylanie na wiele urzadzen jak backend bedzie to umial robic
        if (valid) {
            siloses.forEach(device => {
                new Array(SilosesNumber).fill(1).forEach((o, index) => {
                    setTare(device, {
                        number: index,
                        value: convertWeightToBaseUnit(+tare[index], {fromUnit: TareUnit})
                    })
                })

            })
        }

    };

    setSingleTare = (index) => {
        const valid = !_.get(this.validate(), `tare[${index}]`);
        const {siloses} = this.props;
        const {tare} = this.state;
        if (valid) {
            siloses.forEach(device => {
                setTare(device, {
                    number: index,
                    value: convertWeightToBaseUnit(+tare[index], {fromUnit: TareUnit})
                })
            })
        }
    };

    setValue = (key, value) => {
        this.setState(state => {
            let cloned = _.cloneDeep(state);
            _.set(cloned, key, value);
            return {
                ...cloned,
                changed: true
            }
        })
    };

    validate = () => {
        let errors = {};
        const {tare} = this.state;
        const {t} = this.props;
        tare.forEach((tr, index) => {
            if (_.isNil(tr) || tr === "" || !_.isNumber(+tr)) {
                _.set(errors, `tare[${index}]`, t("required"));
            }
        });

        return errors
    };
    renderTares = () => {
        const {
            tare
        } = this.state;
        let validate = this.validate();
        let result = [];
        for (let index = 0; index < SilosesNumber; index++) {
            let {weight, rawWeight} = this.getValues(this.props, index);
            result.push(
                <div key={index}>
                    <LabeledInput className="make-room-for-button" error={_.get(validate, `tare[${index}]`)}
                                  label={i18next.t("newSettings.silo.tare.header", {
                                          type: index + 1, amount1: convertWeightUnitTo(weight, {
                                          showUnit: true,
                                          unit: UnitTypes.MEDIUM,
                                          fixed: 0
                                          }), amount2: convertWeightUnitTo(rawWeight, {
                                          showUnit: true,
                                          unit: UnitTypes.MEDIUM,
                                          fixed: 0
                                          })
                                      }
                                  )} type={"number"} value={tare[index]}
                                  onChange={(value) => this.setValue(`tare[${index}]`, value)}
                                  unit={<bdi>{getUnit("weight", TareUnit)}</bdi>}/>
                    <Button buttonStyle={"round"} type="button"
                            disabled={_.get(validate, `tare[${index}]`)}
                            onClick={() => this.setSingleTare(index)}
                            icon={<i className={"fas fa-save"}/>}/>
                </div>
            )
        }
        return result;

    };

    render() {
        const {t} = this.props;
        return (
            <>
                <DeviceSettingsCard title={t("newSettings.silo.tare.title")} onButtonClick={this.setAllTares}>
                    {this.renderTares()}
                </DeviceSettingsCard>
            </>
        );
    }

}

Tare.propTypes = {
    siloses: PropTypes.array.isRequired
};

Tare = connect(state => ({
    shadows: state.shadows.shadows,
}))(Tare);

export default withTranslation()(Tare);