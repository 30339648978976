import React, {Component} from 'react';
import {connect} from 'react-redux';
import Card from "../../../../components/basics/card/Card";
import {getAllTranslationLanguages, getTranslation} from "../../../../actions/localizationActions";
import _ from "lodash"
import Select from "../../../../components/basics/select/Select";
import {List, WindowScroller} from "react-virtualized";
import SourceDataComponent from "./source-data-component/SourceDataComponent";
import DestinationDataComponent from "./destination-data-component/DestinationDataComponent";
import {getRemToPx} from "../../../../utils/DOMUtils";
import "./_translate-view.scss"
import ButtonGroup from "../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../components/basics/button/Button";
import Input from "../../../../components/basics/input/Input";
import {
    updateTranslations,
    updateTranslationsNotificationFailure
} from "../../../../api/translations/updateTranslations";
import NewTranslationModal, {ModalName as NewTranslationModalName} from "../../../../components/modals-new/new-translation-modal/NewTranslationModal";
import {bindActionCreators, compose} from "redux";
import {show} from "redux-modal";
import Menu from "../../../../components/basics/menu/Menu";
import MenuItem from "../../../../components/basics/menu/MenuItem";
import {ModalName as ConfirmModalName} from "../../../../components/modals-new/confirm-modal/ConfirmModal";
import {Prompt} from "react-router-dom";
import AddTranslationRowModal, {ModalName as AddTranslationRowModalName} from "../../../../components/modals-new/add-translation-row/AddTranslationRowModal";
import LoadingComponent from "../../../../components/loading/LoadingComponent";
import Checkbox from "../../../../components/basics/checkbox/Checkbox";
import Tooltip from "../../../../components/basics/tooltip/Tooltip";
import Queue from "better-queue";
import QueueMemory from "better-queue-memory";
import {withTranslation} from "react-i18next";
import languages from "../../../../data/languages.json"
import {
    deleteTranslations,
    deleteTranslationsNotificationFailure,
    deleteTranslationsNotificationSuccess
} from "../../../../api/translations/deleteTranslations";
import * as UserTypes from "validators-schema/Api/constants/userTypes";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        user: state.user.user,
        translations: state.language.translations.content,
        acceptedTranslations: state.language.translations.acceptance,
        languages: state.language.availableTranslationLanguages,
        loadingLanguages: state.language.loading.translationLanguages,
        loadingTranslation: state.language.loading.translations
    };
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators({show}, dispatch),
        dispatch
    }
}

class TranslateView extends Component {

    state = {
        languages: this.props.languages,
        source: "pl",
        destination: "en",
        sourceData: null,
        destinationData: null,
        acceptedData: null,
        data: [],
        disableNextButton: false,
        disablePreviousButton: false,
        event: null,
        filter: "",
        hideAccepted: true,
        queueLength: 0,
        pluralsSource: this.props.i18n.services.pluralResolver.getRule("pl").numbers,
        pluralsDestination: this.props.i18n.services.pluralResolver.getRule("en").numbers
    }

    allLanguages = {};
    firstRenderedIndex = 0;
    shift = false;
    container = React.createRef();
    selectContainer = React.createRef();
    filterContainer = React.createRef();

    constructor(props) {
        super(props);
        console.log(this.props);
        this.queue.on("task_queued", this.incrementQueueLength);
        this.queue.on("batch_finish", this.decrementQueueLength);
        this.queue.on("batch_failed", this.decrementQueueLength);
        this.queue.on("drain", () => {
            this.props.dispatch(getTranslation([this.state.source, this.state.destination], false));
        })
    }

    static getTranslation(props, language) {
        return _.get(props.translations, language, null);
    }

    static getAcceptedData(props, language) {
        return _.get(props.acceptedTranslations, language, null);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let source = TranslateView.getTranslation(nextProps, prevState.source);
        let destination = TranslateView.getTranslation(nextProps, prevState.destination);
        let acceptedData = TranslateView.getAcceptedData(nextProps, prevState.destination);
        if (!_.isEqual(prevState.sourceData, source) || !_.isEqual(prevState.destinationData, destination) || !_.isEqual(prevState.acceptedData, acceptedData)) {
            return {
                sourceData: source,
                destinationData: destination,
                acceptedData
            }
        }
        if (!_.isEqual(prevState.languages, nextProps.languages)) {
            return {
                languages: nextProps.languages
            }
        }
        return null;
    }

    incrementQueueLength = () => {
        this.setState(state => ({
            queueLength: state.queueLength + 1
        }))
    }

    decrementQueueLength = (amount, xD) => {
        this.setState(state => {
            // wykonywane sa po 2 zadania
            if (state.queueLength > 2) {
                return {
                    queueLength: state.queueLength - 2
                }
            }
            return {
                queueLength: 0
            }
        })
    }

    async componentDidMount() {
        for (let language of languages) {
            this.allLanguages[language.code] = language.nativeName;
        }

        this.props.dispatch(getAllTranslationLanguages());
        this.props.dispatch(getTranslation([this.state.source, this.state.destination]));
        document.addEventListener("keydown", this.pressedShift);
        document.addEventListener("keyup", this.releasedShift);
        window.addEventListener("beforeunload", this.beforeUnload)

        if (this.state.sourceData && this.state.destinationData) {
            this.setState({
                data: this.createData(this.state.sourceData, this.state.destinationData, this.state.acceptedData)
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(this.state.sourceData, prevState.sourceData) || !_.isEqual(this.state.destinationData, prevState.destinationData)
            || !_.isEqual(this.state.acceptedData, prevState.acceptedData) || prevState.hideAccepted !== this.state.hideAccepted) {
            this.setState({
                data: this.createData(this.state.sourceData, this.state.destinationData, this.state.acceptedData)
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.pressedShift);
        document.removeEventListener("keyup", this.releasedShift);
        window.removeEventListener("beforeunload", this.beforeUnload)
    }

    update(data, callbacks) {
        return new Promise((resolve, reject) => {
            let index = _.findIndex(this.state.data, o => `${o.path}` === data.path);
            updateTranslations([data]).then(res => {
                let d = this.state.data.slice(0);
                d[index].destination = data.value;
                this.setState({
                    data: d
                });
                for (let callback of callbacks) {
                    callback && callback();
                }
                resolve(res);
            }).catch(e => {
                console.error(e);
                updateTranslationsNotificationFailure(e);
                this.scrollToIndex(index);
                reject(e);
            })
        })
    }

    sendData = async (input, cb) => {
        for (let item of input) {
            try {
                await this.update(item.data, item.callbacks);
                cb(null, true);
            } catch (e) {
                cb(null, true);
            }
        }
    }

    queue = new Queue((input, cb) => this.sendData(input, cb), {
        store: new QueueMemory(),
        id: (task, cb) => {
            cb(null, `${task.data.lang}_${task.data.path}`);
        },
        merge: (oldTask, newTask, cb) => {
            if (oldTask.data.hasOwnProperty("accepted")) {
                cb(null, {
                    data: {...newTask.data, accepted: true}, callbacks: [...oldTask.callbacks, ...newTask.callbacks]
                })
            } else if (newTask.data.hasOwnProperty("accepted")) {
                cb(null, {
                    data: {...oldTask.data, accepted: true}, callbacks: [...oldTask.callbacks, ...newTask.callbacks]
                })
            } else {
                cb(null, {...newTask, callbacks: [...oldTask.callbacks, ...newTask.callbacks]});
            }
        },
        batchSize: 2,
        batchDelay: 1000,
        afterProcessDelay: 1000
    });

    addToSaveQueue = (data, callback) => {
        this.queue.push({data, callbacks: [callback]});
    }

    pressedShift = e => {
        if (e.keyCode === 16) {
            this.shift = true;
        }
    }

    releasedShift = e => {
        if (e.keyCode === 16) {
            this.shift = false;
        }
    }

    beforeUnload = e => {
        if (this.state.queueLength > 0) {
            // musi byc cos wstawione, zeby wyswietlil sie komunikat, a chrome, firefox i safari wyswietlaja swoje
            // komunikaty, ktorych nie da sie zmienic
            e.returnValue = '123';
        } else {
            delete e.returnValue;
            e.preventDefault();
        }
    }

    onChangeSource = value => {
        let rule = this.props.i18n.services.pluralResolver.getRule(value);
        this.setState({
            pluralsSource: rule.numbers,
            source: value,
            sourceData: TranslateView.getTranslation(this.props, value)
        });
        this.props.dispatch(getTranslation(value));
    };

    onChangeDestination = value => {
        let rule = this.props.i18n.services.pluralResolver.getRule(value);
        this.setState({
            pluralsDestination: rule.numbers,
            destination: value,
            destinationData: TranslateView.getTranslation(this.props, value)
        });
        this.props.dispatch(getTranslation(value));
    };

    pushToArray(array, path, destination, value, accepted) {
        const {pluralsDestination, pluralsSource} = this.state;
        if (pluralsDestination.length !== pluralsSource.length) {
            if (pluralsDestination.length === 2) {
                // utworz 2 klucze dla liczby pojedynczej i mnogiej
                if (path.includes("_0")) {
                    let p = path.replace("_0", "");
                    let pathPlural = p + "_plural";
                    let accept = _.get(accepted, `${p}.acceptedIDs`, []).find(item => item === this.props.user.LocalUserID) || false;
                    let acceptPlural = _.get(accepted, `${pathPlural}.acceptedIDs`, []).find(item => item === this.props.user.LocalUserID) || false;
                    array.push({
                        value,
                        path: p,
                        destination: _.get(destination, p, ""),
                        accepted: accept,
                        sourcePath: path
                    });
                    array.push({
                        value,
                        path: pathPlural,
                        destination: _.get(destination, pathPlural, ""),
                        accepted: acceptPlural,
                        number: 2,
                        sourcePath: null
                    });
                    return;
                } else {
                    // wywal wszystkie inne klucze, ktore maja w sobie _1, _2 itd. ponieważ są nieużyteczne w tym przypadku
                    for (let i = 1; i < pluralsSource.length; i++) {
                        if (path.includes(`_${i}`)) return;
                    }
                }
            } else {
                if (pluralsSource.length === 2) {
                    if (path.includes("_plural")) {
                        let p = path.replace("_plural", "");
                        // wyrzuc klucz od liczby pojedynczej
                        let index = _.findIndex(array, o => o.path === p);
                        if (index > -1) {
                            let removed = array.splice(index, 1)[0];
                            // dodaj klucze dla docelowego jezyka
                            for (let i = 0; i < pluralsDestination.length; i++) {
                                let pathWithIndex = p + `_${i}`;
                                let accept = _.get(accepted, `${pathWithIndex}.acceptedIDs`, []).find(item => item === this.props.user.LocalUserID) || false;
                                array.push({
                                    value: i === 0 ? removed.value : value,
                                    path: pathWithIndex,
                                    destination: _.get(destination, pathWithIndex, ""),
                                    accepted: accept,
                                    number: i !== 0 ? pluralsDestination[i] : undefined,
                                    sourcePath: i === 0 ? removed.path : null
                                });
                            }
                        }
                        return;
                    }
                } else {
                    if (path.includes("_0")) {
                        let p = path.replace("_0", "");
                        // dodaj klucze dla docelowego jezyka
                        for (let i = 0; i < pluralsDestination.length; i++) {
                            let pathWithIndex = p + `_${i}`;
                            let accept = _.get(accepted, `${pathWithIndex}.acceptedIDs`, []).find(item => item === this.props.user.LocalUserID) || false;
                            array.push({
                                value: value,
                                path: pathWithIndex,
                                destination: _.get(destination, pathWithIndex, ""),
                                accepted: accept,
                                number: i !== 0 ? pluralsDestination[i] : undefined,
                                sourcePath: i === 0 ? path : null
                            });
                        }
                        return;
                    } else {
                        // nie dodawaj kluczy konczacych sie na _1 itd. bo zostaly juz dodane
                        for (let i = 1; i < pluralsSource.length; i++) {
                            if (path.includes(`_${i}`)) return;
                        }
                    }
                }
            }
        }
        let accept = _.get(accepted, `${path}.acceptedIDs`, []).find(item => item === this.props.user.LocalUserID) || false;
        let number = null;
        let endsWithNumberRegex = new RegExp("_\\d$");
        if (endsWithNumberRegex.test(path)) {
            number = +path.slice(-1);
            if (number === 0) number = null;
        }
        if (path.endsWith("_plural")) {
            number = 1;
        }
        array.push({
            value,
            path,
            destination: _.get(destination, path, ""),
            accepted: accept,
            sourcePath: path,
            number: pluralsDestination[number]
        });
    }

    createData(source, destination, accepted, path = "") {
        if (!source) return [];
        let array = [];
        for (let key in source) {
            let value = source[key];
            if (Array.isArray(value)) {
                for (let i = 0; i < value.length; i++) {
                    let p = path ? `${path}.${key}[${i}]` : `${key}[${i}]`;
                    if (Array.isArray(value[i]) || typeof value[i] === "object") {
                        array = [...array, ...this.createData(value[i], destination, accepted, p)]
                    } else {
                        this.pushToArray(array, p, destination, value[i], accepted);
                    }
                }
            } else if (typeof value === "object") {
                array = [...array, ...this.createData(value, destination, accepted, path ? `${path}.${key}` : key)];
            } else {
                let p = path ? `${path}.${key}` : key;
                let accept = _.get(accepted, `${p}.acceptedIDs`, []).find(item => item === this.props.user.LocalUserID) || false;
                if ((this.state.hideAccepted && !accept) || !this.state.hideAccepted) {
                    this.pushToArray(array, p, destination, value, accepted);
                }
            }
        }
        return array;
    }

    copyFromSourceToDestination(value, index) {
        const data = this.state.data.slice(0);
        data[index].destination = value;
        this.setState({
            data
        });
        this.addToSaveQueue({lang: this.state.destination, value, path: `${data[index].path}`});
    }

    onCheckboxChange(value, index) {
        const data = this.state.data.slice(0);
        data[index].accepted = value;
        this.setState({
            data
        });
        this.addToSaveQueue({
            lang: this.state.destination,
            path: `${data[index].path}`,
            value: data[index].destination,
            accepted: value
        })
    }

    onRemoveClick(index) {
        const {show, t} = this.props;
        const data = this.state.data.slice(0);
        show(ConfirmModalName, {
            title: t("newSettings.translate.edit.removeTitle"),
            text: t("newSettings.translate.edit.removeText", {path: data[index].path}),
            confirmText: t("yes"),
            closeText: t("cancel"),
            onConfirmed: props => {
                data[index].loading = true;
                this.setState({
                    data
                });
                deleteTranslations([
                    {lang: this.state.destination, path: data[index].path},
                    {lang: this.state.source, path: data[index].sourcePath},
                    // {lang: "pl", path: data[index].path},
                    // {lang: "en", path: data[index].path},
                    // {lang: "es", path: data[index].path},
                    // {lang: "ru", path: data[index].path},
                ]).then(res => {
                    this.props.dispatch(getTranslation([this.state.destination, this.state.source], false));
                    deleteTranslationsNotificationSuccess(res);
                }).catch(e => {
                    deleteTranslationsNotificationFailure(e);
                    const d = this.state.data.slice(0);
                    d[index].loading = false;
                    this.setState({
                        data: d
                    });
                });
                props.handleHide();
            }
        })
    }

    renderRow = ({key, style, index}) => {
        const {data} = this.state;
        const {user} = this.props;
        let object = data[index];
        let regex = new RegExp("{\\w+}|{{\\w+}}", "g");
        let found = object.value.match(regex);
        return (
            <div key={key} style={style} className="translate-row">
                <div className="translate-col">
                    <SourceDataComponent path={object.path} value={object.value} number={object.number || null}/>
                </div>
                <div className="translate-arrow">
                    <Button buttonStyle={"round"} icon={<i className="fas fa-arrow-right"/>}
                            onClick={() => this.copyFromSourceToDestination(object.value, index)}
                            disabled={object.value === object.destination}/>
                </div>
                <div className="translate-col">
                    <DestinationDataComponent lang={this.state.destination} value={object.destination} index={index}
                                              path={object.path} sourceValue={object.value}
                                              addToQueue={this.addToSaveQueue} inserts={found || []}/>
                </div>
                <div className="translate-col-checkbox">
                    <Checkbox label={""} id={key} checked={object.accepted}
                              onChange={value => this.onCheckboxChange(value, index)}/>
                </div>
                <div className="translate-col-checkbox">
                    {
                        object.sourcePath && user.UserType === UserTypes.SERVICE &&
                        <Button buttonStyle={"round"}
                                icon={object.loading ? <i className="fas fa-circle-notch fa-fw fa-spin"/> :
                                    <i className="fas fa-trash fa-fw"/>}
                                onClick={() => this.onRemoveClick(index)} disabled={object.loading}/>
                    }
                </div>
                <div className="index">
                    {index + 1}
                </div>
            </div>
        )
    }

    scrollToIndex(index) {
        // TODO jak naprawia WindowScroller + List.scrollToRow to podmienić
        let cardRect = this.container.current.getBoundingClientRect();
        let selectRectHeight = this.selectContainer.current.clientHeight;
        let rem = getRemToPx(1);
        window.scrollTo({
            top: index * rem * 5 + cardRect.top + window.scrollY + selectRectHeight + (2 * rem),
            behavior: 'smooth'
        })
    }

    findNextEmpty() {
        const {data} = this.state;
        return _.findIndex(data, o => !o.destination || o.destination === o.value, this.firstRenderedIndex + 2); // +2 bo pasek na gorze przykrywa jeden wiersz
    }

    scrollToNextEmpty = () => {
        let index = this.findNextEmpty();
        if (index >= 0) {
            this.scrollToIndex(index);
        }
    }

    findPreviousEmpty() {
        const data = this.state.data.slice(0);
        let index = data.length - _.findIndex(data.reverse(), o => !o.destination || o.destination === o.value, data.length - this.firstRenderedIndex);
        if (index < data.length) {
            return index;
        }
        return -1;
    }

    scrollPreviousEmpty = () => {
        let index = this.findPreviousEmpty();
        if (index >= 0) {
            this.scrollToIndex(index);
        }
    }

    onRowsRendered = ({startIndex}) => {
        const {disablePreviousButton, disableNextButton} = this.state;
        this.firstRenderedIndex = startIndex;
        let obj = {};
        // blokowanie nastepnego jezeli przescrolluje ostatni niewypelniony
        if (this.findNextEmpty() < 0) {
            obj.disableNextButton = true;
        } else if (disableNextButton) {
            obj.disableNextButton = false;
        }
        // blokowanie poprzedniego jezeli przescrolluje pierwszy niewypelniony
        if (this.findPreviousEmpty() < 0) {
            obj.disablePreviousButton = true;
        } else if (disablePreviousButton) {
            obj.disablePreviousButton = false;
        }
        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    filterPredicate = o => JSON.stringify(o).toLowerCase().includes(this.state.filter.toLowerCase())

    filterBelow = () => {
        const {data} = this.state;
        let index = _.findIndex(data, this.filterPredicate, window.scrollY === 0 ? 0 : this.firstRenderedIndex + 2);
        if (index < 0) {
            index = _.findIndex(data, this.filterPredicate);
        }
        this.scrollToIndex(index);
    }

    filterAbove = () => {
        const {data} = this.state;
        let index = data.length - _.findIndex(data.slice(0).reverse(), this.filterPredicate, data.length - this.firstRenderedIndex);
        if (index > data.length) {
            index = data.length - _.findIndex(data.slice(0).reverse(), this.filterPredicate) - 1;
        }
        this.scrollToIndex(index);
    }

    onSubmitFilter = e => {
        if (this.shift) {
            this.filterAbove();
        } else {
            this.filterBelow();
        }
        e.preventDefault();
    }

    onChangeFilter = value => {
        this.setState({
            filter: value
        })
    }

    onAddButtonClick = (e) => {
        this.setState({
            event: e
        })
    }

    onAddLanguageClick = () => {
        this.props.show(NewTranslationModalName, {languages: this.allLanguages, current: this.state.languages});
    }

    onAddTranslationClick = () => {
        this.props.show(AddTranslationRowModalName, {
            // language: this.state.sourceData,
            locale: this.state.source
        })
    }

    showBeforeLeave = location => {
        const {t} = this.props;
        this.props.show(ConfirmModalName, {
            title: t("newSettings.translate.edit.notSaved"),
            text: t("newSettings.translate.edit.notSavedText"),
            confirmText: t("yes"),
            closeText: t("cancel"),
            onConfirmed: props => {
                this.queue.use(new QueueMemory());
                this.setState({
                    queueLength: 0
                }, () => {
                    this.props.history.push(location.pathname);
                    props.handleHide();
                });
            }
        })
        return false;
    }

    onHideAccepted = value => {
        this.setState({
            hideAccepted: value
        })
    }

    onExchangeClick = () => {
        this.setState(state => ({
            source: state.destination,
            destination: state.source,
            pluralsSource: state.pluralsDestination,
            pluralsDestination: state.pluralsSource
        }))
    }

    render() {
        const {t, user} = this.props;
        const {
            languages, source, destination, data, disableNextButton, disablePreviousButton, event, filter,
            hideAccepted, queueLength
        } = this.state;
        return (
            <Card className="translate-view" forwardRef={this.container}>
                <LoadingComponent
                    isLoading={this.props.loadingLanguages || this.props.loadingTranslation}/>
                <form className="translate-filter" ref={this.filterContainer} onSubmit={this.onSubmitFilter}>
                    <div className="filter-content">
                        <Input type={"text"} onChange={this.onChangeFilter} value={filter}
                               placeholder={t("newSettings.translate.edit.search")}/>
                        <div className="filter-actions">
                            <Button buttonStyle={"text"} icon={<i className="fas fa-arrow-down"/>} type={"button"}
                                    onClick={this.filterBelow}/>
                            <Button buttonStyle={"text"} icon={<i className="fas fa-arrow-up"/>} type={"button"}
                                    onClick={this.filterAbove}/>
                        </div>
                    </div>
                    <div className="save-indicator">
                        <i className={queueLength > 0 ? "fas fa-fw fa-circle-notch fa-spin" : "fas fa-check fa-fw"}/>
                    </div>
                </form>
                <div className="translate-row languages" ref={this.selectContainer}>
                    <div className="translate-col">
                        <Select value={source}
                                options={languages.filter(item => item !== destination).map(item => ({
                                    value: item,
                                    name: this.allLanguages[item] || `? (${item})`
                                }))} onChange={this.onChangeSource} clearButton={false}/>
                    </div>
                    <div className="translate-arrow">
                        <Button buttonStyle={"round"} icon={<i className="fas fa-exchange"/>}
                                onClick={this.onExchangeClick}/>
                    </div>
                    <div className="translate-col">
                        <Select value={destination}
                                options={languages.filter(item => item !== source).map(item => ({
                                    value: item,
                                    name: this.allLanguages[item] || `? (${item})`
                                }))} onChange={this.onChangeDestination} clearButton={false}/>
                    </div>
                    <div className="translate-col-checkbox">
                        <Tooltip tooltipContent={t("newSettings.translate.edit.showHideAccepted")}>
                            <div>
                                <Checkbox label={""} checked={hideAccepted} onChange={this.onHideAccepted}/>
                            </div>
                        </Tooltip>
                    </div>
                    <div className="translate-col-checkbox"/>
                </div>
                <WindowScroller>
                    {
                        ({height, isScrolling, scrollTop, onChildScroll}) => (
                            <List autoHeight height={height} rowCount={data.length} rowHeight={getRemToPx(5)}
                                  width={window.innerWidth}
                                  rowRenderer={this.renderRow} isScrolling={isScrolling}
                                  scrollTop={scrollTop} data={data} className={"w-100"} onScroll={onChildScroll}
                                  onRowsRendered={this.onRowsRendered}/>
                        )
                    }
                </WindowScroller>
                <ButtonGroup fixed>
                    <Button buttonColor={"info"} buttonStyle={"round"} icon={<i className="fas fa-arrow-up"/>}
                            disabled={disablePreviousButton} onClick={this.scrollPreviousEmpty}/>
                    <Button buttonColor={"info"} buttonStyle={"round"} icon={<i className="fas fa-arrow-down"/>}
                            disabled={disableNextButton} onClick={this.scrollToNextEmpty}/>
                    {user.UserType === UserTypes.SERVICE &&
                    <Button buttonColor={"info"} buttonStyle={"round"} icon={<i className="fas fa-plus"/>}
                            onClick={this.onAddButtonClick}/>}
                    <Menu event={event}>
                        <MenuItem
                            onClick={this.onAddLanguageClick}>{t("newSettings.translate.edit.newLocale")}</MenuItem>
                        <MenuItem
                            onClick={this.onAddTranslationClick}>{t("newSettings.translate.edit.newTranslation")}</MenuItem>
                    </Menu>
                </ButtonGroup>
                <NewTranslationModal/>
                <AddTranslationRowModal/>
                <Prompt when={queueLength > 0} message={this.showBeforeLeave}/>
            </Card>
        );
    }

}

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(TranslateView)
