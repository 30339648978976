import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./_source-data.scss";
import Tooltip from "../../../../../components/basics/tooltip/Tooltip";
import {withTranslation} from "react-i18next";

class SourceDataComponent extends Component {

    path = React.createRef();

    isPathOverflowing() {
        if (!this.path.current) return false;
        return this.path.current.scrollWidth > this.path.current.clientWidth;
    }

    createPath() {
        const {path} = this.props;
        let component = <div className="source-data-path" ref={this.path}>{path}</div>;
        if (this.isPathOverflowing()) {
            return (
                <Tooltip tooltipContent={path}>
                    {component}
                </Tooltip>
            )
        }
        return component;
    }

    render() {
        const {value, number, t} = this.props;
        return (
            <div className="source-data">
                {
                    number &&
                    <div className="source-data-value">
                        {t("newSettings.translate.source.count", {number})}
                    </div>
                }
                {
                    !number &&
                    <div className="source-data-value">{value}</div>
                }
                {this.createPath()}
            </div>
        );
    }
}

SourceDataComponent.propTypes = {
    path: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    number: PropTypes.number,
};

export default withTranslation()(SourceDataComponent);